<template>
    <div class="service-agreement-wrapper">
        <h2>隐私声明</h2>
        <div class="service-agreement-content">
            欢迎阅读超赞隐私声明（下称本声明）。本声明适用你在超赞无偿或有偿使用超赞产品（包括但不限于小程序等超赞产品）。超赞是北京代码澎湃科技有限公司（下称超赞）运营的网站，本声明所述的超赞产品为超赞旗下产品。
            <div>在使用超赞产品前，请你务必仔细阅读并透彻理解本声明，在确认充分理解并同意后使用相关产品。一旦你开始使用超赞各项产品，即表示你已充分理解并同意本声明。如对本声明内容有任何疑问、意见或建议，你可通过超赞提供的各种联系方式与超赞联系。
                超赞承诺尊重和保护你的隐私，并将此作为超赞事业活动的基础和社会责任及义务。因此在你访问超赞网站时，超赞会尽力帮助你维护互联网个人数据的控制权。</div>
        </div>

        <div class="service-agreement-title">
            一、对你的信息的收集
        </div>
        <div class="service-agreement-content">
            为向你提供更好的服务，超赞会要求你在进行<u><strong>注册超赞用户、产品购买、产品购买后应监管部门要求进行实名认证、在线咨询等情况下提供你的姓名(或名称)、地址、联络方式及身份证等有效证件信息，</strong></u>以便我们为你提供快速准确的优质服务。
        </div>
        <div class="service-agreement-title">
            二、对你的信息的使用
        </div>
        <div class="service-agreement-content">
            你提供的信息将受到严格保密，超赞使用你的信息只是为了更全面地了解你的需要并为你提供更佳的服务。在一些情况下，可能还会用你的信息与你取得联系以进行市场调查或为你提供我们认为你可能感兴趣的市场信息。
        </div>
        <div class="service-agreement-content">
            <u><strong>若超赞将信息用于本声明未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求你的同意。</strong></u>
        </div>
        <div class="service-agreement-content">
            如超赞停止运营超赞产品或服务，超赞将及时停止继续收集你个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知你，并对超赞所持有的与已关停业务相关的个人信息进行删除或匿名化处理。
        </div>
        <div class="service-agreement-title">
            三、对你的信息的控制
        </div>
        <div class="service-agreement-content">
            当你通过注册或其它方式向超赞提供你的信息时，超赞不会把你的信息共享、转让或出租给他人。你的许可总被首先保障，超赞不会向任何不代表超赞的第三方透露你的信息。除非根据法律、法规或监管部门按法律执行，超赞不会在未经你事先知晓或许可的情况下，以与上述内容无关的方式随意使用或分享你提供给超赞的信息。
        </div>
        <div class="service-agreement-content">
            超赞可能会将收集到的你的信息与来自第三方的数据进行整理或合并，并用于向你推广产品或服务。
        </div>
        <div class="service-agreement-content">
            <u><strong>超赞可能委托授权合作伙伴为你提供某些服务，超赞仅会出于本隐私声明的合法、正当、必要、特定、明确的目的共享你的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。</strong></u>
        </div>
        <div class="service-agreement-content">
            在超赞发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，超赞会要求新的持有你个人信息的公司、组织继续受本隐私声明的约束，否则超赞将要求该公司、组织和个人重新向你征求授权同意。
        </div>
        <div class="service-agreement-title">
            四、对你的信息的进入或修改
        </div>
        <div class="service-agreement-content">
            超赞为了保护你的隐私和安全，将采取合理的措施验证身份，之后，你才能进入或修改你的信息。超赞力求使你的信息保持准确，并为你提供了包括使你能够在线访问你的信息、注册数据的能力，使你可以更新或修改你的信息。请注意，从超赞到第三方网站的链接仅作为一种方便服务提供给你，如果使用这些链接，你将离开超赞，而第三方网站可能会收集与你有关的个人身份信息。因此，超赞对第三方网站及其任何信息、软件或其他产品、材料、或因使用它们而可能获得的任何结果不予认可，也不作任何陈述和保证。
        </div>
        <div class="service-agreement-title">
            五、你的信息的安全性
        </div>
        <div class="service-agreement-content">
            超赞已采取合理可行的安全防护措施保护你的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。你的数据将被存放在有密码控制的服务器中，因而任何对你资料的访问都将受到限制并被严格保护。
        </div>
        <div class="service-agreement-content">
            在不幸发生个人信息安全事件后，超赞将按照法律法规的要求向你告知：安全事件的基本情况和可能的影响、超赞已采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施等。事件相关情况超赞将以邮件、信函、电话、推送通知等方式告知你，难以逐一告知个人信息主体时，超赞会采取合理、有效的方式发布公告。
        </div>
        <div class="service-agreement-content">
            同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
        </div>
        <div class="service-agreement-title">
            六、你的信息的披露
        </div>
        <div class="service-agreement-content">
            <u><strong>你充分知晓，以下情形中，超赞共享、转让、公开披露你的个人信息无需事先征得你的授权同意：</strong></u>
        </div>
        <div class="service-agreement-content">
            <div>（1）<u><strong>与国家安全、国防安全有关的</strong></u></div>
            <div>（2）<u><strong>与公共安全、公共卫生、重大公共利益有关的；</strong></u></div>
            <div>（3）<u><strong>与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</strong></u></div>
            <div>（4）<u><strong>出于维护你或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</strong></u></div>
            <div>（5）<u><strong>你自行向社会公众公开的个人信息；</strong></u></div>
            <div>（6）<u><strong>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</strong></u></div>
            <div>（7）<u><strong>保护超赞及其客户的权利、财产，包括协议的执行和遵守适用于服务的策略；</strong></u></div>
            <div>（8）<u><strong>保护超赞及其雇员、客户和公众的权利、财产或安全。</strong></u></div>
        </div>
        <div class="service-agreement-content">请知悉，根据适用的法律，若超赞对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向你通知并征得你的同意。</div>
        <div class="service-agreement-title">
            七、Cookie技术的使用
        </div>
        <div class="service-agreement-content">
            随着互联网对各项新技术的应用，超赞为访客带来了完全个性化的体验。超赞会使用Cookies的技术来分析网页的使用情形。Cookie是放置在你的电脑硬盘中的一段要求权限的文字。使用此项技术并不表示超赞可自动获悉有关你的任何信息。超赞可以确定你使用的计算机类型，但使用Cookies的主要目的还在于使你在访问超赞网站时获得更佳的体验。
        </div>
        <div class="service-agreement-content">
            同时你也拥有完全的自主权，可以将网络浏览器设置为通知你Cookie的放置请求，或者完全拒绝Cookie。你可以删除包含Cookie的文件。这些文件被保存为Internet浏览器的一部分。
        </div>
        <div class="service-agreement-title">
            八、如何处理未成年人的信息
        </div>
        <div class="service-agreement-content">
            超赞推定你具有相应的民事行为能力。如你为未成年人，超赞要求你请你的父母或监护人仔细阅读本隐私声明，并在征得你的父母或监护人同意的前提下使用超赞的服务、产品或向超赞提供信息。
        </div>
        <div class="service-agreement-content">
            对于经父母或监护人同意使用超赞的产品或服务而收集未成年人个人信息的情况，超赞只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
        </div>
        <div class="service-agreement-title">
            九、本声明的更改
        </div>
        <div class="service-agreement-content">
            本声明的解释权归结于超赞，超赞可能适时修订本隐私声明的条款，该修订构成本隐私声明的一部分。<u><strong>如该等修订造成你在本隐私声明下权利的实质减少，超赞将在修订生效前通过在主页上显著位置提示或向你发送电子邮件或以其他方式通知你。在该种情况下，若你继续使用超赞的服务，即表示同意受经修订的本隐私声明的约束。</strong></u>
        </div>
        <div class="service-agreement-title">
            十、管辖与法律适用
        </div>
        <div class="service-agreement-content">
            <u><strong>本隐私声明的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</strong></u>
        </div>
        <div class="service-agreement-content">
            若你和超赞发生任何纠纷或争议，首先应友好协商解决；协商不成的，任何一方均可向北京市海淀区人民法院提起诉讼。
        </div>
        <div class="service-agreement-content"><strong>本声明自公布时生效。</strong></div>
        <div class="service-agreement-content"><strong>以上声明，你可随时在超赞阅读、复制或下载。</strong></div>
    </div>
</template>

<script>
    export default {
        name: "ServiceAgreement"
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";

</style>
